import { useEffect } from "react";

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

export default function CookieConsent() {
    useEffect(() => {
        if (!document.getElementById('cc--main')) {
            window.CookieConsentApi = window.initCookieConsent();
            window.CookieConsentApi.run({
                autorun: true,
                current_lang: 'en',
                autoclear_cookies: true,
                mode:'opt-in',
                page_scripts: true,
                gui_options: {
                    consent_modal: {
                        layout: 'cloud',
                        position: 'bottom center',
                        transition: 'slide',
                        swap_buttons: false
                    },
                    settings_modal: {
                        layout: 'box',
                        transition: 'zoom'
                    }
                },
                languages: {
                    en: {
                        consent_modal: {
                            title: '',
                            description: 'To understand how you use our Website and to improve your experience, we collect certain important information (e.g., cookies). To learn about what we collect and why read our <a aria-label="Cookie policy" class="cc-link" href="https://www.radnet.com/privacy-statement" target="_blank">Privacy policy</a>. <button type="button" data-cc="c-settings" class="cc-link" aria-haspopup="dialog">Manage your cookie preferences.</button>',
                            primary_btn: {
                                text: 'Accept',
                                role: 'accept_all'
                            }
                        },
                        settings_modal: {
                            title: '<img class="cc-title-logo" src="https://cdnwest.radnet.com/brand/logo-radnet.svg"> <h3>| Cookie preferences</h3>',
                            save_settings_btn: 'Save settings',
                            accept_all_btn: 'Accept all',
                            reject_all_btn: 'Reject all',
                            cookie_table_headers: [
                                {col1: 'Name'},
                                {col2: 'Domain'},
                                {col3: 'Expiration'},
                                {col4: 'Description'},
                                {col5: 'Type'}
                            ],
                            blocks: [
                                {
                                    title: 'Cookie usage',
                                    description: 'We use cookies to give you the best experience. By using our site, you agree to our use of cookies. Alternatively, you have the choice to opt-in or opt-out of each optional category when desired.'
                                }, {
                                    title: 'Essential information',
                                    description: 'This collected information is necessary to provide you with the critical features available on our Website. Because this information is essential to your experience, you are unable to alter these settings and still use the Website.',
                                    toggle: {
                                        value: 'necessary',
                                        enabled: true,
                                        readonly: true
                                    },
                                    cookie_table: [
                                        {
                                            col1: '^waveimaging',
                                            col2: 'waveimagingnetwork.com',
                                            col3: '1 day',
                                            col4: 'Site specific',
                                            col5: 'Permanent cookies',
                                            is_regex: true
                                        },
                                        {
                                            col1: 'AMP',
                                            col2: 'waveimagingnetwork.com',
                                            col3: '1 day',
                                            col4: 'AMP',
                                            col5: 'Permanent cookies'
                                        }
                                    ]
                                }, {
                                    title: 'Non-Essential Information',
                                    description: 'This information is used to enhance your experience on our Website. If we are unable to collect this important yet non-essential information, certain functions and items (e.g. chat and location maps) will not be available or work as intended.',
                                    toggle: {
                                        value: 'analytics',
                                        enabled: true,
                                        readonly: false,
                                        reload: 'on_disable'
                                    },
                                    cookie_table: [
                                        {
                                            col1: '^_ga',
                                            col2: 'google.com',
                                            col3: '2 years',
                                            col4: 'Google Analytics session',
                                            col5: 'Permanent cookie',
                                            is_regex: true
                                        },
                                        {
                                            col1: '_gid',
                                            col2: 'google.com',
                                            col3: '1 day',
                                            col4: 'Google Analytics ID',
                                            col5: 'Permanent cookie'
                                        },
                                        {
                                            col1: '_gat',
                                            col2: 'google.com',
                                            col3: '1 minute',
                                            col4: 'Google Analytics request rate control',
                                            col5: 'Temporary cookie'
                                        }
                                    ]
                                }, {
                                    title: 'California Residents',
                                    description: 'If you reside in California and would like to make a request related to your personal information, please fill out our Consumer Rights Request Form.<br><a class="c-bn c-bn-bl" href="https://www.radnet.com/consumer-rights-ca" target="_blank">Your Privacy Choices</a>',
                                }
                            ]
                        }
                    }
                }
            });
        }

    }, []);

    return null;
}