import React, { useState } from "react"

const ListItem = ({ item }) => {
  let [active, setActive] = useState(false)
  const showInfo = e => {
    console.log(e.currentTarget)
    setActive(!active)
  }
  let fixTitle = str => {
    const reg1 = /RadNet Orange County \| /
    const reg2 = /RadNet Long Beach \| /
    if(str.match(reg1)){
      return str.replace(reg1, '')
    }
    if(str.match(reg2)) {
      return str.replace(reg2, '')
    }
  }

  // let convertToPhone = string => {
  //   if (string.length === 10) {
  //     return (
  //       string.substring(0, 3) +
  //       "-" +
  //       string.substring(3, 6) +
  //       "-" +
  //       string.substring(6, string.length)
  //     )
  //   } else {
  //     return string
  //   }
  // }
  // let convertToList = string => {
  //   return string.split(",")
  // }
  let capitalize = str => str.substr(0,1).toUpperCase() + str.substr(1)

  let {
    title,
    address,
    city,
    postal,
    url
  } = item

  let numbers = item.numbers
  let services = Object.entries(item.services)

  return (
    <li className={`list-dropdown ${active ? "active" : ""}`}>
      <div className="list-titlebar" onClick={showInfo}>
        {title}
        <span ></span>
      </div>
      <div className={`${active ? "" : "hide"} mt-3 columns`}>
        <div className="column">
          <div className="container">
            <h4 className={`has-text-weight-semibold`}>Address</h4>
            <p>{address} </p>
            <p>
              <span className={`${city ? "" : "hide"}`}>{city}</span>,{" "}
              <span className={``}>CA</span>{" "}
              <span className={`${postal ? "" : "hide"}`}>{postal}</span>
            </p>
            <br />
            {numbers.map((phone, id)=> 
              <p><span id={id} className={`has-text-weight-semibold`}>{capitalize(phone.type)}: </span>{phone.number}</p>
            )}
            <br />
            <h4 className={`has-text-weight-semibold`}>Services</h4>
             {services.map((service, id)=> {
              let s = service[1].name
              return (<p><span id={id} className={``}>{capitalize(s)}</span></p>)
             })}
          </div>
        </div>
        <div className="column has-text-right">
                    <p className={`${url ? '' : 'hide'}`}><a className="button" href={url} target="_blank">Learn More</a></p>
                </div>
      </div>
    </li>
  )
}

export default ListItem
