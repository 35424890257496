import React, { Component } from "react"
import waveImagingLogo from "../images/logo-Waveimaging.png"
import waveImagingMap from "../images/waveimaging-map-banner.jpg"
//import cache from '../../cache'
//import locations from "../data/locations"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ListItem from "../components/locationItem"
import CookieConsent from "../components/CookieConsent"
//import Form from "../components/form"

class IndexPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      locations: []
    }
  }
  componentDidMount(){
      //change to data from RadNet (update)
      //get locations from OC & LB

      //if fresh data, use this ->

      //fetch(`https://stage.radnet.com/locator/search/json?locator_search=CA`)
      fetch(`https://www.radnet.com/locator/search/json?locator_search=CA`)
        .then(res => res.json())
        .then(res => {
          let data = res

          let sbLocations = data.matched.filter(item => item.did === "2081")
          let ocLocations = data.matched.filter(item => item.did === "28")
          let lbLocations = data.matched.filter(item => item.did === "22" && item.nid !== "871")
          let locations = [...sbLocations, ...ocLocations, ...lbLocations]
          locations = locations.sort((a,b)=>{
              if(a.title > b.title){
                  return 1
              }
              if(a.title < b.title){
                  return -1
              }
              return 0
          })
          this.setState({locations: locations})
        })
        .catch(err => console.log(err))
  }
  render() {
    let {locations} = this.state
    console.log(locations)

    return (
      <Layout>
        <SEO title="WaveImaging" />
        <section id="splash" className="container">
          <div className={`wave-info centered mb-5`}>
            <img
              src={waveImagingLogo}
              alt="Wave Imaging Logo"
              style={{ width: `100%`, maxWidth: `600px` }}
            />

            <h2 style={{fontWeight: `bold`, textTransform: `uppercase`}}>A Premier Network of outpatient imaging<br />centers in the Orange and Los Angeles Counties</h2>
          </div>
          <div className="mt-6 has-text-centered ">
            <a className="button is-success is-large" href="tel:+19493875000">
              <i className="fas fa-phone-square-alt"></i>
              &nbsp;&nbsp;949-387-5000
            </a>
            <a className="button is-success is-large smt" href="https://myradiologyconnectportal.com/ImagingCenter/WCR%20PP" target="_blank">
            <i class="fas fa-user"></i>
            &nbsp;&nbsp; <span>Orange County<br/>Online Scheduling</span>
            </a>
            <a className="button is-success is-large smt" href="https://myradiologyconnectportal.com/ImagingCenter/LCIPP" target="_blank">
            <i class="fas fa-user"></i>
            &nbsp;&nbsp; <span>Long Beach<br/>Online Scheduling</span>
            </a>
          </div>
          <div style={{fontWeight: `bold`, textAlign: `center`, fontSize:'20px', marginTop:'15px'}}>
          <a href="https://waveimagingnetwork.com/forms/Optum-Ref-Pad-2024_02-08-2024_DIGITAL_FINAL.pdf" download target="_blank">
            Download Optum Referral Form
          </a>
          </div>
          <div className="map-area">
            <img className="map" src={waveImagingMap} alt="Wave Imaging" />
          </div>
          {/*<Form locations={locations} />*/}          
          <div className="locations-info columns">
            <div className="column map-region-oc">
              <div>
                <ul>
                  {locations.slice(0, locations.length / 2).map((item, idx) => {
                    return <ListItem item={item} key={idx} />
                  })}
                </ul>
              </div>
            </div>
            <div className="column map-region-lb">
              <div>
                <ul>
                  {locations
                    .slice(locations.length / 2, locations.length)
                    .map((item, idx) => {
                      return <ListItem item={item} key={idx} />
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className="bottom-info">
            <h4>Pricing Transparency</h4>
            <p>The Good Faith Estimate (GFE) is a required notification sent by healthcare providers to uninsured and self-pay patients that outlines reasonably expected charges for a medical item or service, such as an imaging study. The GFE is based on information known at the time of scheduling. Uninsured and self-pay patients will automatically receive a GFE via text or email when they schedule an appointment at our center. Uninsured and self-pay patients may also request a GFE prior to scheduling. Click this button for more detailed information on GFE.</p>
            <div class="text-center">
              <a target="_blank" class="button btn btn-default" href="https://cdnwest.radnet.com/pdf/Right%20to%20a%20GFE%20Notice.pdf">View Details</a>
              <a target="_blank" class="button btn btn-default" href="https://myradiologyconnectportal.com/home/gfe">Online Estimator</a>
            </div>
          </div>
        </section>
        <CookieConsent/>
      </Layout>
    )
  }
}

export default IndexPage
